import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './style.sass';

class Contacts extends Component {
    state = {
        data: this.props.data
    }

    handleGoal = () => {
        window.ym && window.ym(62628037,'reachGoal','mobile_phone');
    }

    render() {
        const { data } = this.state;

        return (
            <div className="wrapper wrapper--other wrapper--vis">
                <div className="full-wrapper">
                    <div className="wrapper__content">
                        <div className="contacts">
                            <div className="contacts__title">
                                <h2>{data.title}</h2>
                            </div>
                            <div className="contacts__content">
                                {
                                    data.person.map((item, index) => (
                                        <div key={index}
                                            className="contacts__content-item"
                                        >
                                            {item.type &&
                                                <div className="contacts__content-item-type">
                                                    {item.type}
                                                </div>
                                            }

                                            <div className="contacts__content-item-title">
                                                {item.name}
                                            </div>
                                            <div className="contacts__content-item-info">
                                                {item.position}
                                                <div onClick={() => {this.handleGoal()}} dangerouslySetInnerHTML={{__html: item.tel.code}}/>
                                                <div dangerouslySetInnerHTML={{__html: item.email.code}}/>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

Contacts.propTypes = {};

export default Contacts;
