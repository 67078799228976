import React, {Component} from 'react';
import './style.sass';
import FastStartForm from '../fast-start-form/form';

const EcoSystem = (props) => {
    const { data } = props;
    return (
        <div className="full-wrapper" id='ecosystem' >
            <div className="ecosystem">
                <div className="ecosystem__title">
                    <h2><span className="green-color">Как попасть</span> в экосистему</h2>
                </div>

                <div className="ecosystem__text">
                    <p>Важная деталь – до недавнего времени все перечисленные «прелести жизни» Инновационного центра, приводящие к таким результатам, были доступны исключительно компаниям, уже получившим статус участника «Сколково».</p>
                    <p>Но статус участника получается через прохождение экспертизы независимых специалистов из нескольких стран. На это нужны дополнительные усилия и, что важнее, дополнительное время, которое в венчурном рынке на вес золота.</p>
                </div>
                <div className="what-gives-correction">
                    <div className="what-gives-correction__text">
                        В интернете можно встретить рекламу разных организаций, предлагающих гарантированный статус участника «Сколково». Все они мошенники. Гарантировать получение статуса не может никто. Независимые эксперты, привлекаемые для оценки стартапа, могут забраковать его по любому из параметров.
                    </div>
                </div>

                <div className="ecosystem__text">
                    <p><b>Чтобы помочь стартапам, у которых лишнего времени нет, Технопарк «Сколково» запустил программу Super All In. Она может стать полезным первым шагом на пути к статусу участника «Сколково».</b></p>
                    <p>Экспертизу нужно будет проходить на общих основаниях, но с Super All In можно в кратчайшие сроки совершить физический переезд, а уже потом, сидя в Инновационном центре, полноценно подготовиться, подать заявление и получить статус участника, в том числе, воспользовавшись советами соседей, которые уже проходили этот путь.</p>
                    <p>Всё что нужно, чтобы воспользоваться Super All In – решить сколько сотрудников целесообразно здесь разместить и выбрать подходящий <a href="/#tarif" target='_blank'>тариф</a>.</p>
                </div>

                <div className="ecosystem__btn">
                    <FastStartForm />

                </div>

            </div>
        </div>
    );
};

export default EcoSystem;
