import React, {Component} from 'react';
import './style.sass';
import FastStartForm from '../fast-start-form/form';

const WhatGives = (props) => {
    const { data } = props;
    return (
        <div className="full-wrapper" id='what-gives' >
            <div className="what-gives">
                <div className="what-gives__title">
                    <h2>Что даёт работа на территории <span className="green-color">Инновационного центра</span></h2>
                </div>
                <div className="what-gives__text">
                    <p>Экосистема Инновационного центра предоставляет всё необходимое для быстрого старта проекта, ускоренного развития и масштабирования без потерь.</p>
                    <p>Проекты на уровне идеи «Сколково» обеспечивает НИОКР-инфраструктурой для проверки гипотез и создания опытной разработки. К услугам компаний 1500 единиц высокотехнологичного оборудования от 81 поставщика.</p>
                </div>

                <div className="what-gives__img-block">
                    <img src="/images/i1.png" alt=""/>
                </div>

                <div className="what-gives-correction">
                    <div className="what-gives-correction__text">
                        Стартапы с бизнес-планом получают доступ к сообществу инвесторов и бизнес-ангелов AdVentureLand, где к их услугам несколько форматов питчинга, а также индивидуализированный поиск инвесторов. В 2019 году участники мероприятий сообщества привлекли 100 миллионов инвестиций. Каждый пятый рубль из них был получен непосредственно в результате питчинга в «Сколково».
                    </div>
                </div>
                <div className="what-gives__text">
                    <p>На этапе запуска бренда 300+ менторов «Сколково» помогут бизнесу совершить идеальный старт. На стадии промышленного образца можно воспользоваться скаутинговой программой Технопарка, принять участие в акселераторе/конкурсе по заказу одного из 139 промышленных партнёров Фонда. В каждый момент действует минимум один такой, чаще 2-3, запущенных в партнёрстве с компаниями уровня Visa, Enel, Bayer, S7 Group, «Россети», «Росатом», AstraZeneca и т.п.</p>
                </div>
                <div className="what-gives__img-block">
                    <img src="/images/i2.png" alt=""/>
                </div>
                <div className="what-gives__text">
                    <p>В период масштабирования стартапы, испытывающие кадровый голод, могут вступать в HR-сообщество Технопарка, здесь им помогут усилить HR-бренд, найти кандидатов нужного профиля и удержать их в конкуренции с крупными игроками.</p>
                </div>
                <div className="what-gives__img-block">
                    <img src="/images/i3.png" alt=""/>
                </div>
                <div className="what-gives__text">
                    <p>Наконец, на любой стадии развития все обитатели «Сколково» обмениваются лучшими практиками, контактами и вообще любыми идеями с многотысячным сообществом бизнесменов, разработчиков инновационных технологий, квалифицированных специалистов разных областей, работающих рядом.</p>
                </div>

                <div className="what-gives-correction">
                    <div className="what-gives-correction__text">
                        «Главное в «Сколково» – хорошие соседи: такие же высокотехнологичные стартапы, которые тоже быстро растут. С ними есть в чём посоревноваться, но ещё больше – что обсудить, – говорит CEO Технопарка Ренат Батыров. – В таком окружении вы быстрее, чем где-либо, найдете сотрудников, менторов, инвесторов, партнеров, клиентов. Это безвозмездно, безвозвратно, без условий поделиться долей в своем бизнесе и претензий на созданную здесь интеллектуальную собственность».
                    </div>
                </div>

                <div className="what-gives__img-block">
                    <img src="/images/i4.png" alt=""/>
                </div>
                <div className="what-gives__text">
                    <p>Технопарк «Сколково» – возможно сильнейшая площадка для установления профессиональных контактов в России. Десять тысяч арендаторов Инновационного центра здесь имеют возможно общаться, как на профессиональных мероприятиях – лекциях, тренингах, мастер-классах, так и неформальных – от йоги по утрам, до пятничной пинты в пабе. Кроме того, в обычный (не пандемийный) год здесь более 600 конференций бизнес- и технологической тематики, которые посещают в сумме полмиллиона гостей, включая представителей крупных корпораций и предпринимателей, заинтересованных в передовых исследованиях.</p>
                </div>
                <div className="what-gives__img-block">
                    <img src="/images/i5.png" alt=""/>
                </div>

                <div className="what-gives-result">
                    <div className="what-gives-result__title">
                        Результаты участников
                    </div>
                    <div className="what-gives-result__text">
                        <p>На одиннадцатый год функционирования «Сколково» эффект экосистемы вполне осязаем. Показатели выживаемости и успешности здесь значительно превышают стандартные  венчурные.</p>
                    </div>
                    <div className="what-gives-result-item">
                        <span className="what-gives-result-item__num">
                            01
                        </span>
                        <div className="what-gives-result-item__text">
                            Резиденты Инновационного центра растут быстрее рынка (на порядок), быстрее компаний из РБК 500 (значительно) и даже быстрее участников «Сколково», размещённых за пределами ИЦ.<br/>
                            В период 2017-18 годов выручка медианного резидента выросла в три раза, а за три года, прошедшие с запуска Технопарка, – в сорок: с 400 тысяч в 2016-м до 17 с лишним миллионов в 2019-м. Рост международной выручки резидентов в 2018-19 годах составил 40%.
                        </div>
                    </div>
                    <div className="what-gives-result-item">
                        <span className="what-gives-result-item__num">
                            02
                        </span>
                        <div className="what-gives-result-item__text">
                            Инвесторы склонны доверять резидентам Инновационного центра. Частные вложения в них растут в среднем на 34% в год. Что характерно, здесь размещена только пятая часть участников «Сколково», но на их долю, например, в 2019-м пришлась половина (49,3%) валовых инвестиций, привлечённых сколковскими компаниями.
                        </div>
                    </div>
                    <div className="what-gives-result-item">
                        <span className="what-gives-result-item__num">
                            03
                        </span>
                        <div className="what-gives-result-item__text">
                            В 2019 году резиденты «Сколково» зарегистрировали в три раза больше патентов, чем за 2018-й.
                        </div>
                    </div>

                    <div className="what-gives-result-item__btn">
                        <FastStartForm />
                    </div>

                </div>
            </div>
        </div>
    );
};

export default WhatGives;
