import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './style.sass';
import FastStartForm from '../fast-start-form/form';

class MainFast extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
        };
    }

    componentDidMount() {

    }


    render() {
        const { width, data } = this.state;

        return (
            <div className="wrapper wrapper--bg bg-fast-main wrapper--check">
                <div className="full-wrapper">
                    <div className="wrapper__content">
                        <div className="main-fast">

                            <div className="main-fast__title">
                                {data.title} <span>{data.subtitle}</span>
                            </div>
                            <div className="main-fast__btn">
                                <FastStartForm />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

MainFast.propTypes = {};

export default MainFast;
