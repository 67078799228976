import React, { Component, Fragment } from "react"
import { Formik } from "formik"
import Icon1 from "../../../static/images/svg/form-success-white.svg"
import axios from "axios"
import InputMask from "react-input-mask"
import Modal from "react-modal"

const customStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    zIndex: "10",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "675px",
    maxWidth: "90%",
  },
}

class FastStartForm extends Component {
  state = {
    modalSuccess: false,
    modalFail: false,
    isOpen: false,
    isDisabled: false,
  }

  initialValues = { name: "", email: "", phone: "", surname: "" }

  closeModalSuccess = () => {
    this.setState({
      modalSuccess: false,
    })
  }

  closeModalFail = () => {
    this.setState({
      modalFail: false,
    })
  }

  openForm = () => {
    this.setState({
      isOpen: true,
    })
  }

  closeForm = () => {
    this.setState({
      isOpen: false,
    })
  }

  setDisabledBtn = status => {
    this.setState({
      isDisabled: status,
    })
  }

  validate = values => {
    let errors = {}

    if (!values.email) {
      errors.email = "Email не может быть пустым"
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,15}$/i.test(values.email)
    ) {
      errors.email = "Введен некорректный Email"
    }

    if (values.phone) {
      if (!/(\+?\d[- .]*){7,13}/i.test(values.phone)) {
        errors.phone = "Введен некорректный номер телефона"
      }
    }

    return errors
  }

  onSubmit = async (values, actions) => {
    actions.setSubmitting(false)
    this.setDisabledBtn(true)
    values.email = values.email.toLowerCase()
    try {
      const result = await axios.post(
        "https://billing.sk.w6p.ru/api/v1/claims",
        values
      )
      if (result.data.success) {
        window.ga("send", "event", "konsult", "click")

        window.ym(62628037, "reachGoal", "fast_start")

        this.setState({
          modalSuccess: true,
        })

        actions.resetForm(true)
        this.setDisabledBtn(false)
      }
    } catch (e) {
      console.log("err", e)
      this.setState({
        modalFail: true,
      })
      this.setDisabledBtn(false)
    }
  }

  render() {
    const { modalSuccess, modalFail } = this.state

    return (
      <Fragment>
        <button
          className="button"
          type="button"
          onClick={() => {
            this.openForm()
          }}
        >
          <span>Оставить заявку</span>
        </button>
        <Modal
          isOpen={this.state.isOpen}
          ariaHideApp={false}
          onRequestClose={this.closeModalSuccess}
          style={customStyles}
          contentLabel="Form Modal"
        >
          <button className="modal-close" onClick={this.closeForm} />
          <Formik
            initialValues={this.initialValues}
            validate={this.validate}
            validateOnChange={false}
            validateOnBlur
            onSubmit={this.onSubmit}
          >
            {props => (
              <form
                onSubmit={props.handleSubmit}
                className="form fast-start-form"
              >
                <div className="form__title">Оставить заявку</div>
                <div className={"form__success"}>
                  <div className="success">
                    <div className="success__icon">
                      <img src={Icon1} alt="success" />
                    </div>
                    <div className="success__title">
                      Форма успешно отправлена!
                    </div>
                  </div>
                </div>
                <div className={"form__wrapper "}>
                  <label className="form__label">
                    <span className="form__input">
                      <input
                        placeholder="Имя"
                        name="name"
                        type="text"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.name}
                      />
                    </span>
                    {props.errors.name && props.touched.name ? (
                      <span className="help-block error">
                        {props.errors.name}
                      </span>
                    ) : null}
                  </label>

                  <label className="form__label">
                    <span className="form__input">
                      <input
                        placeholder="Фамилия"
                        name="surname"
                        type="text"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.surname}
                      />
                    </span>
                    {props.errors.surname && props.touched.surname ? (
                      <span className="help-block error">
                        {props.errors.surname}
                      </span>
                    ) : null}
                  </label>

                  <label className="form__label">
                    <span className="form__input">
                      <InputMask
                        mask="+7 (999) 999-99-99"
                        maskChar=" "
                        placeholder="Телефон"
                        type="text"
                        name="phone"
                        data-validation-regexp="(\+?\d[- .]*){7,13}"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.phone}
                      />
                    </span>
                    {props.errors.phone && props.touched.phone ? (
                      <span className="help-block error">
                        {props.errors.phone}
                      </span>
                    ) : null}
                  </label>
                  <label className="form__label">
                    <span className="form__input">
                      <input
                        name="email"
                        type="email"
                        placeholder="E-mail"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.email}
                      />
                    </span>
                    {props.errors.email && props.touched.email ? (
                      <span className="help-block error">
                        {props.errors.email}
                      </span>
                    ) : null}
                  </label>
                  <div className="form__text">
                    Нажимая кнопку Отправить, я&nbsp;выражаю согласие на&nbsp;
                    <a
                      href="https://divein.sk.ru/consent"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      обработку персональных данных
                    </a>
                  </div>
                  <button
                    className="button button--center"
                    type="submit"
                    disabled={this.state.isDisabled}
                  >
                    <span>Отправить</span>
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </Modal>

        <Modal
          isOpen={modalSuccess}
          ariaHideApp={false}
          onRequestClose={this.closeModalSuccess}
          style={customStyles}
          contentLabel="Success Modal"
        >
          <button className="modal-close" onClick={this.closeModalSuccess} />
          <div className="response">
            <div className="response__title">
              Здорово!
              <span>Все получилось!</span>
            </div>
            <div className="response__desc">
              В&nbsp;ближайшее время с&nbsp;вами свяжется специалист.
            </div>
          </div>
        </Modal>
        <Modal
          isOpen={modalFail}
          ariaHideApp={false}
          onRequestClose={this.closeModalFail}
          style={customStyles}
          contentLabel="Success Modal"
        >
          <button className="modal-close" onClick={this.closeModalFail} />
          <div className="response">
            <div className="response__title">Ой, что-то случилось</div>
            <div className="response__desc">
              Обновите страницу и&nbsp;попробуйте
              <br />
              заполнить форму еще раз.
            </div>
          </div>
        </Modal>
      </Fragment>
    )
  }
}

FastStartForm.propTypes = {}

export default FastStartForm
