import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './style.sass'
import ConsultationForm from '../form/form';

class TariffCost extends Component {
    state = {
        data: this.props.data
    }

    render() {
        const { data } = this.state;

        return (
            <div className="wrapper wrapper--cost">
                <div className="full-wrapper">
                    <div className="wrapper__content">
                        <div className="tariff-cost">
                            <div className="tariff-cost__title">
                                <h2 dangerouslySetInnerHTML={{__html: data.title}}/>
                            </div>
                            <div className="tariff-cost__content">
                                <div className="tariff-cost__info">
                                    <div className="tariff-cost-info">
                                        <div className="tariff-cost-info__head">
                                            <div className="tariff-cost-info__column">Тариф</div>
                                            <div className="tariff-cost-info__column">Cтоимость ₽/месяц</div>
                                        </div>
                                        <div className="tariff-cost-info__content">
                                            {
                                                data.tariffs.map((item, index) => (
                                                    <div key={index}
                                                         className="tariff-cost-info__row"
                                                    >
                                                        <div className="tariff-cost-info__column">
                                                            <div className="tariff-cost-info__column-title">
                                                                {item.name}
                                                            </div>
                                                            <div className="tariff-cost-info__column-value">
                                                                {
                                                                    item.description.map((item, index) => (
                                                                        <span key={index}
                                                                              dangerouslySetInnerHTML={{__html: item.text}}
                                                                        />
                                                                    ))
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="tariff-cost-info__column">
                                                            <div className="tariff-cost-info__column-title">
                                                                {item.price} <span>₽/месяц</span>
                                                            </div>
                                                            <div className="tariff-cost-info__column-value">
                                                                <span>{item.unit}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="tariff-cost__form">
                                    <ConsultationForm/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

TariffCost.propTypes = {};

export default TariffCost;
