import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './style.sass';

const ParticipantStatus = (props) => {
    const { data } = props;
    return (
        <div className="full-wrapper" id='status'>
            <div className="participant-status">
                <div className="participant-status__title">
                    <h2>Что даёт <span className="green-color">статус участника</span></h2>
                </div>
                <div className="participant-status__text">
                    <p>Статус участника «Сколково» гарантий выживания и процветания, конечно же, не даёт – в венчуре гарантий вообще не бывает. Однако налоговые льготы, доступ к грантам, поддержка кластеров и серьезный эндорсмент стартапа (особенно на российском рынке) могут сыграть решающую роль в развитии компании.</p>
                    <p>Со значком «Сколково» количество потенциальных партнёров, готовых с вами пообщаться, резко увеличивается. Участникам Фонда всегда проще находить клиентов, партнёров для пилотных проектов и источники финансирования.</p>
                </div>


                <div className="participant-success">
                    <div className="participant-success__title">
                        Истории успеха
                    </div>
                    <div className="participant-success__row">
                        <div className="participant-success-item">
                            <div className="participant-success-item__inner">
                                <div className="participant-success-item__icon">
                                    <img src="/images/participants/ngnix.png" alt=""/>
                                </div>
                                <div className="participant-success-item__text">
                                    Зарегистрировав юрлицо, создатели самого популярного веб-сервера в мире Nginx первым делом стали участниками «Сколково», получили освобождение от налогов и активно стали расти. В прошлом году компания, продуктом которой пользуются Netflix, Instagram, «Яндекс», Vkontakte и другие, была продана за $670 миллионов.
                                </div>
                                <div className="participant-success-item__link">
                                    <a href="https://navigator.sk.ru/orn/1110301" target='_blank'>Подробнее</a>
                                </div>
                            </div>
                        </div>

                        <div className="participant-success-item">

                            <div className="participant-success-item__inner">
                                <div className="participant-success-item__icon">
                                    <img src="/images/participants/fapp.png" alt=""/>
                                </div>
                                <div className="participant-success-item__text">
                                    Редактор портретных фотографий на основе нейросетей (помните марафон «состаренных» фото в соцсетях?)  FaceApp  оказался в центре скандала с личными данными в США. А поручительство «Сколково» помогло оперативно его уладить. Результат: 150 миллионов скачиваний и $5 миллионов выручки в 2019 году.
                                </div>
                                <div className="participant-success-item__link">
                                    <a href="https://navigator.sk.ru/orn/1122314" target='_blank'>Подробнее</a>
                                </div>
                            </div>

                        </div>

                        <div className="participant-success-item">

                            <div className="participant-success-item__inner">
                                <div className="participant-success-item__icon">
                                    <img src="/images/participants/uchi.png" alt=""/>
                                </div>
                                <div className="participant-success-item__text">
                                    В период подготовки к международной экспансии образовательной платформы Учи.ру «Сколково» с Агентством стратегических инициатив помогли с поиском инвестиций. На днях сервис с представительствами во всех странах БРИКС (Бразилия, Россия, Индия, Китай, ЮАР) и 110 миллионами месячной аудитории получил под четыре миллиарда инвестиций от Mail.ru Group.
                                </div>
                                <div className="participant-success-item__link">
                                    <a href="https://navigator.sk.ru/orn/1120461" target='_blank'>Подробнее</a>
                                </div>
                            </div>

                        </div>

                        <div className="participant-success-item">

                            <div className="participant-success-item__inner">
                                <div className="participant-success-item__icon">
                                    <img src="/images/participants/viriom.png" alt=""/>
                                </div>
                                <div className="participant-success-item__text">
                                    В 2016 году «Сколково» выдало разработчикам препарата от ВИЧ, компании «Вириом» грант в 150 миллионов на масштабирование. Помимо этого, соответствующие службы Фонда помогали регистрировать патенты, находить партнёров среди гигантов фармы и выводить лекарство на рынок. Через год компания зарегистрировала первую версию препарата и по итогам 2019-го имеет выручку под 300 миллионов.
                                </div>
                                <div className="participant-success-item__link">
                                    <a href="https://navigator.sk.ru/orn/1120749" target='_blank'>Подробнее</a>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default ParticipantStatus;
