import React from "react";
import PropTypes from "prop-types";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Tariff from '../components/tariff/tariff';
import MainWhy from '../components/main-why/main-why';
import TariffIncludes from '../components/tariff-includes/tariff-includes';
import TariffCost from '../components/tariff-cost/tariff-cost';
import MainInfrastructure from '../components/main-infrastructure/main-infrastructure';
import MainTransport from '../components/main-transport/main-transport';
import Contacts from '../components/contacts/contacts';
import MainFast from '../components/main-fast';
import Toc from '../components/toc';
import ParticipantStatus from '../components/participant-status';
import WhatGives from '../components/what-gives';
import EcoSystem from '../components/ecosystem';

export const FastPageTemplate = ({data}) => (
  <>
    <SEO title="Home"/>

    <MainFast data={data.markdownRemark.frontmatter.mainFastSlide}/>

    <Toc data={data.markdownRemark.frontmatter.toc} />
    <ParticipantStatus />
    <WhatGives />
    <EcoSystem />
    <Contacts data={data.markdownRemark.frontmatter.contacts}/>
  </>
)

FastPageTemplate.propTypes = {
  title: PropTypes.string,
}

const FastPage = ({data}) => (
  <Layout page='fast-page' >
    <FastPageTemplate data={data}/>
  </Layout>
);

export default FastPage;

export const pageQuery = graphql`
    query FastPageTemplate {
        markdownRemark(frontmatter: { templateKey: { eq: "fast-page" } }) {
            frontmatter {
                mainFastSlide {
                    title,
                    subtitle,
                },
                toc {
                    text,
                    riscTitle,
                    riscDescription,
                    tocTitle,
                    tocList{
                        name,
                        hash
                    }, 
                },
                howToGet {
                    title,
                    image {
                          publicURL
                        },
                    ways {
                        way,
                        description
                    },
                },
                contacts {
                    title,
                    person {
                        name,
                        position,
                        tel {
                            code
                        },
                        email {
                            code
                        },
                    },
                },
            }
        }
    }
`
