import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './style.sass';
import scrollTo from 'gatsby-plugin-smoothscroll';

class Toc extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
        };
    }

    componentDidMount() {

    }


    render() {
        const { data } = this.state;

        return (
            <>
                    <div className="full-wrapper">
                        <div className="toc">
                            <div className="toc__text">
                                {data.text}
                            </div>
                            <div className="toc__risk-title">
                                {data.riscTitle}
                            </div>
                            <div className="toc__risk-desc">
                                {data.riscDescription}
                            </div>

                            {/*<div className="toc-list">*/}
                            {/*    <div className="toc-list__title">*/}
                            {/*        {data.tocTitle}*/}
                            {/*    </div>*/}
                            {/*    {*/}
                            {/*        data.tocList &&*/}
                            {/*        data.tocList.map((i, index) => {*/}
                            {/*            return (*/}
                            {/*                <div className="toc-list-item">*/}
                            {/*                    <div className="toc-list-item__link" data-id={i.hash} onClick={() => {scrollTo(`#${i.hash}`);}}>*/}
                            {/*                        <span>{index +1}.</span> {i.name}*/}
                            {/*                    </div>*/}
                            {/*                </div>*/}
                            {/*            )*/}
                            {/*        })*/}
                            {/*    }*/}
                            {/*</div>*/}

                            <div className="toc-participant">
                                <h2>Участники <span className="green-color">Сколково</span></h2>

                                <div className="toc-participant__grid">
                                    <div className="toc-participant-item">

                                        <div className="toc-participant-item__icon">
                                            <img src="/images/shuttle.svg" alt="" />
                                        </div>

                                        <div className="toc-participant-item__text">
                                            <div className="toc-participant-item__title">
                                                2 154
                                            </div>
                                            <div className="toc-participant-item__desc">
                                                компании
                                            </div>
                                        </div>

                                    </div>

                                    <div className="toc-participant-item">

                                        <div className="toc-participant-item__icon">
                                            <img src="/images/part2.svg" alt="" />
                                        </div>

                                        <div className="toc-participant-item__text">
                                            <div className="toc-participant-item__title">
                                                36 452
                                            </div>
                                            <div className="toc-participant-item__desc">
                                                численность персонала участников
                                            </div>
                                        </div>

                                    </div>


                                    <div className="toc-participant-item">

                                        <div className="toc-participant-item__icon">
                                            <img src="/images/part3.svg" alt="" />
                                        </div>

                                        <div className="toc-participant-item__text">
                                            <div className="toc-participant-item__title">
                                                641
                                            </div>
                                            <div className="toc-participant-item__desc">
                                                количество полученных участниками патентов
                                            </div>
                                        </div>

                                    </div>




                                    <div className="toc-participant-item">

                                        <div className="toc-participant-item__icon">
                                            <img src="/images/part4.svg" alt="" />
                                        </div>

                                        <div className="toc-participant-item__text">
                                            <div className="toc-participant-item__title">
                                                113,8<span> млрд. р.</span>
                                            </div>
                                            <div className="toc-participant-item__desc">
                                                валовая выручка участников
                                            </div>
                                        </div>

                                    </div>

                                    <div className="toc-participant-item">

                                        <div className="toc-participant-item__icon">
                                            <img src="/images/part5.svg" alt="" />
                                        </div>

                                        <div className="toc-participant-item__text">
                                            <div className="toc-participant-item__title">
                                                13,2<span> млрд. р.</span>
                                            </div>
                                            <div className="toc-participant-item__desc">
                                                совокупный объем внешнего финансирования участников
                                            </div>
                                        </div>

                                    </div>

                                    <div className="toc-participant-item">

                                        <div className="toc-participant-item__icon">
                                            <img src="/images/part6.svg" alt="" />
                                        </div>

                                        <div className="toc-participant-item__text">
                                            <div className="toc-participant-item__title">
                                                12,1<span> млрд. р.</span>
                                            </div>
                                            <div className="toc-participant-item__desc">
                                                международная выручка участников
                                            </div>
                                        </div>

                                    </div>




                                </div>

                            </div>

                        </div>
                    </div>
            </>
        );
    }
}

Toc.propTypes = {};

export default Toc;
